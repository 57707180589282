import React, { FormEvent, useState } from "react"
import styled from "styled-components"
import { useForm, NestedValue } from "react-hook-form";
import axios from "axios"
import { ImCheckmark, ImNotification } from "react-icons/im"
import Zoom from 'react-reveal/Zoom';

interface IContactFormProps {
  className?: string
}

const ContactForm: React.FC<IContactFormProps> = ({ className }) => {
  const { register, reset, handleSubmit, setValue, formState: { errors, dirtyFields } } = useForm();
  const [serverError , setServerError] = useState("")
  const [formSubmited, setFormSubmited] = useState(false)
  const handleFormData = async (data: any) => {
    const fields = []
    for (let type in data) {
      fields.push({
        value: data[type],
        type: type.toUpperCase()
      })
    }
    const dataToSend = {
      identifier: process.env.WEBFRONT_ID,
      iso: "en",
      fields: fields
    }
    try {
      const response = await axios.post(process.env.FORM_CONTACT_API_URL || "" ,
        dataToSend,
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          mode: 'cors',
          cache: 'no-cache',
          redirect: 'follow',
          referrer: 'no-referrer',
        }
      )
      setFormSubmited(true)
      setTimeout(() => {
        reset({
          name:"",
          email:"",
          subject: "",
          text: ""
        })
        setFormSubmited(false)
      }, 6000)
    }catch(error) {
      if (error.response) {
        setServerError(error.response.data.message)
      } else if (error.request) {

        // console.log(error.request);
      } else {
        // console.log('Error', error.message);
      }
    }
  }
  // console.log("ERRORS: ", errors)
  return (
    <div className={className}>
      {!formSubmited ? <form onSubmit={handleSubmit(handleFormData)} method="POST">
        <div className="form-field">
          <label htmlFor="name">Name</label>
          <input id="name" {...register("name", { required: true })} placeholder="Name" />
          {errors.name?.type === "required" && <div className="error-info">This field is required</div>}
        </div>
        <div className="form-field">
          <label htmlFor="email">E-mail</label>
          <input id="email" {...register("email",
            {
              required: true,
              pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            })} placeholder="E-mail" />
          {errors.email?.type === "required" && <div className="error-info">This field is required</div>}
          {errors.email?.type === "pattern" && <div className="error-info">Please type valid email address!</div>}
        </div>
        <div className="form-field submit">
          <button type="submit">Send</button>
        </div>
        <div className="form-field">
          <label htmlFor="subject">Subject</label>
          <input id="subject" {...register("subject")} placeholder="Subject" />
        </div>
        <div className="form-field">
          <label htmlFor="message">Message</label>
          <textarea id="message" cols={30} rows={10}
                    placeholder="Message" {...register("text", { required: true })} />
          {errors.message?.type === "required" && <div className="error-info">This field is required</div>}
        </div>
      </form> :
        <div className="success-contact-form" style={{display: "flex", textAlign:"center", flexDirection:"column", justifyContent: "center", alignItems: "center", width:"100%", height:"400px"}}>
          <div>
          { serverError ? <div>
              <div><ImNotification size="75px" color="red" /></div>
              <span style={{
                display: "inline-block",
                marginTop: "30px",
                fontSize: "2rem",
                fontWeight: "bold",
                marginBottom: "30px"
              }}>{serverError}</span>
            </div> :
            <Zoom delay={300}>
              <div><ImCheckmark size="75px" color="green" /></div>
              <span style={{
                display: "inline-block",
                marginTop: "30px",
                fontSize: "2rem",
                fontWeight: "bold",
                marginBottom: "30px"
              }}>Thank you for contacting us!</span>
              <br />
              <p>We are processing your request. <br /> You can expect to hear from our team shortly. </p>
            </Zoom>
        }
          </div>
        </div>
      }
    </div>
  )
}

export default styled(ContactForm)`
  margin-top:50px;
  .error-info {
    width:100%;
    margin-top: 10px;
    padding: 5px;
    border: 1px solid red;
    color:red;
    font-weight: bold;
  }
  form {
    padding-bottom: 70px;
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
    button[type=submit]{
      border:none;
      background:black;
      padding:15px 20px;
      color:white;
      width:100%;
      text-transform:uppercase;
    }
  }
  button[type=submit] {
    transition:all .3s ease;
    &:hover {
      background:#615f5f;
    }
  }
  .form-field {
    width:100%;
    flex-shrink:0;
    // align-items: center;
    display:flex;
    flex-direction: column;
    margin-bottom:15px;
    @media screen and (min-width:900px) {
      width:32%;
      &:nth-child(4){
        align-self:flex-start;
      }
      &:nth-child(5){
        width:66%;
      }
    }
    &.submit {
      position: absolute;
      width:100%;
      bottom:0;
      left:50%;
      transform: translateX(-50%);
      @media screen and (min-width:500px) {
        width:50%;
      }
      @media screen and (min-width:900px) {
        position:relative;
        width:32%;
        bottom: initial;
        left:initial;
        transform: translateX(0);
      }
    }
    label {
      display:none;
    }
    input {
      border:none;
      box-shadow:0 0 1px 1px #e2e2e2;
      padding:15px;
      width:100%;
    }
    textarea {
      border:none;
      width:100%;
      padding:15px;
      box-shadow:0 0 1px 1px #e2e2e2;
    }
  }
`
